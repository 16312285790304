<template lang="pug">
  v-dialog(v-model="dialog" persistent width="70%")
    v-card
      DialogHeader(:title="title" @close-dialog="closeDialog")
      v-card-text
        v-container(grid-list-md)
          v-layout(row wrap)
            //氏名
            v-flex(xs12 md2)
              v-text-field(readonly box label="氏名" v-model="record.name")

            //契約
            v-flex(xs12 md2)
              ContractTypeSelect(:readonly="true" :clearable="false"  :code.sync="record.contractType")
            //1次委託先
            v-flex(xs12 md3)
              v-text-field(readonly box label="1次委託先" v-model="record.primaryDeliveryCompany.companyName")
            //所属会社
            v-flex(xs12 md3)
              v-text-field(readonly box label="所属会社" v-model="record.deliveryCompanyName")
            //所属部署
            v-flex(xs12 md3 v-if="isDisplayDepartment")
              v-text-field(readonly box label="所属部署" v-model="record.department")

          v-layout(row wrap)
            //電話番号
            v-flex(xs12 md4)
              v-text-field(readonly box label="電話番号" v-model="record.mobile")

            //メールアドレス
            v-flex(xs12 md4)
              v-text-field(readonly box label="メールアドレス" v-model="record.email")

            //ナビッピGPS製造番号
            v-flex(xs12 md4)
              v-text-field(readonly box label="ナビッピGPS製造番号" v-model="record.gpsProductNo")

          v-layout(row wrap)
            //運転可能車格
            v-flex(xs12 md4)
              VehicleTypeMultiSelect(:readonly="true" :clearable="false"  :code.sync="record.drivableVehicleTypeIdList")

            //運転可能リフト
            v-flex(xs12 md4)
              LiftTypeSelect(:readonly="true" :clearable="false" :code.sync="record.handlableLiftType" :errorHandlerMethod="getErrorMessages")

            //基本車両
            v-flex(xs12 md4)
              v-text-field(readonly box label="基本車両" v-model="record.regularUseVehicleFullNumberDisp")

      DialogFooter(:displaySave="false")
</template>

<script>
import DialogHeader from "@/components/common/EditDialogHeader";
import DialogFooter from "@/components/common/EditDialogFooter";
import ContractTypeSelect from "@/components/common/select/ContractTypeSelect";
import VehicleTypeMultiSelect from "@/components/common/select/VehicleTypeMultiSelect";
import LiftTypeSelect from "@/components/common/select/LiftTypeSelect";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "../../mixin/EditForm";
import * as Enum from "@/assets/js/enum";

export default {
  name: "DriverDetailDialog",
  components: {
    DialogHeader,
    DialogFooter,
    ContractTypeSelect,
    VehicleTypeMultiSelect,
    LiftTypeSelect
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      dialog: false,
      title: "乗務員詳細",
      record: {}
    };
  },
  methods: {
    /**
     * カード詳細ダイアログを表示
     */
    openDialog(item) {
      this.dialog = true;
      this.record = item;
      this.loadData(item);
    },
    /**
     * カード詳細ダイアログを閉じる
     */
    closeDialog() {
      this.dialog = false;
    },
    /**
     * データ取得
     */
    loadData(item) {
      this.$store.dispatch("dispatch/fetchDriver", item.driverId).then(value => {
        if (value) this.record = value;
      });
    }
  },
  computed: {
    isDisplayDepartment: {
      get() {
        return this.record.contractType === Enum.ContractType.Employee.code;
      }
    }
  }
};
</script>
